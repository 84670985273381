<template>
  <!-- TOP Nav Bar -->
  <div class="iq-top-navbar">
    <div class="">
      <nav class="navbar navbar-expand-lg navbar-light pt-0 pb-0" style="line-height: 60px;" >
        <a class="navbar-brand" :href="VUE_APP_SERVER_URL">
          <div class="iq-navbar-logo d-flex justify-content-between">
            <img :src="logo" class="img-fluid navBarLogo" alt="logo">
          </div>
        </a>
        <div @click="MenuShowHide" class="navbar-toggler" style="background-color:transparent" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <div class="wrapper-menu">
              <div class="main-circle">
                <i class="ri-menu-line"></i>
              </div>
            </div>
          </div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto navbar-list">
            <li class="nav-item active">
              <a href="https://www.gide.ai">
               Home
              </a>
            </li>
            <li class="nav-item">
              <a href="https://www.gide.ai/about">
                About
              </a>
            </li>
            <li class="nav-item">
              <a href="https://www.gide.ai/activities">
                Activities
              </a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle pointer" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Events
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" @click="openUrl('/public/GideEvents')">Upcoming Events</a>
                <a class="dropdown-item" @click="openUrl('/public/GideEvents?past_event=true')">Previous Events</a>
              </div>
            </li>
            <li class="nav-item">
              <a href="https://www.gide.ai/blog">
                Blogs
              </a>
            </li>
            <li class="nav-item">
              <a href="https://www.gide.ai/gallery">
                Gallery
              </a>
            </li>
            <li class="nav-item">
              <a href="https://www.gide.ai/contact-us">
                Contact Us
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link pointer">
                <span @click="signOut()" v-if="userData && userData.user_name">
                  {{userData.user_name}} (Sign out)
                </span>
                <span @click="openUrl('/login')" v-else>
                  Sign In/Sign Up
                </span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>

  <!-- TOP Nav Bar END -->
</template>
<style>
#sidebar-scrollbar {
  height: 100% !important;
}
.navbar-list{
  color: var(--iq-body-text);
}
@media only screen and (max-width:991px) and (min-width:320px)  {
  .nav-item{
    width: 100%;
    padding-left: 10px;
    border-top: 1px solid var(--iq-border-light);
    }
  #navbarSupportedContent .navbar-nav.navbar-list{
    width: 100%;
  }
  #navbarSupportedContent .navbar-nav.navbar-list li > a{
    line-height: 43px;
    min-height: 43px;
    color: var(--iq-body-text);
  }
  .iq-top-navbar .navbar-collapse{
    box-shadow: none !important;
  }
  .nav-item:first-child{
    border: none;
  }
  .iq-top-navbar .navbar{
    margin-top: 6px;
  }
  .dropdown{
    padding-left: 10px;
  }
}
#navbarSupportedContent .navbar-nav.navbar-list li > a{
  color: var(--iq-body-text);
  font-size: 15px;
}
.iq-top-navbar{
  line-height: 0px;
  min-height:0px;
}
a.dropdown-item{
  color: var(--iq-body-text) !important;
  padding-left: 15px;
  text-transform: capitalize;
}
.dropdown-menu{
  border-color: transparent;
    border-top: 1px solid var(--iq-border-light);
    border-radius: 0px !important;
}
</style>
<script>
import { mapGetters } from "vuex"
export default {
  name: "NavBarStyle1",
  props: {
    logo: { type: String, default: require("../../../assets_gide/img/logo/GideWhiteNRed.jpeg") }
  },
  components: {
  },
  computed: {
    ...mapGetters({
      bookmark: "Setting/bookmarkState"
    }),
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  data () {
    return {
      VUE_APP_SERVER_URL: process.env.VUE_APP_SERVER_URL,
      globalSearch: "",
      showSearch: false,
      showMenu: false,
      vmSelectedUser: null,
      cvUserOptions: []
    }
  },
  mounted () {
    this.MenuShowHide()
    document.addEventListener("click", this.closeSearch, true)
  },
  methods: {
    /**
     * openUrl
     */
    openUrl (url) {
      window.open(url, "_parent")
    },
    miniSidebar () {
      this.$emit("toggle")
    },
    openSearch () {
      this.showSearch = true
      this.showMenu = "iq-show"
      this.globalSearch = ""
    },
    closeSearch (event) {
      let classList = event.target.classList
      if (!classList.contains("searchbox") && !classList.contains("search-input")) {
        this.removeClass()
      }
    },
    removeClass () {
      this.showSearch = false
      this.showMenu = ""
      this.globalSearch = ""
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * signOut
     */
    async signOut () {
      try {
        await this.$store.dispatch("User/setUserLogoutAction")
        setTimeout(async () => {
          await this.$router.push("/login").then(() => {
            window.location.reload()
          })
        }, 200)
      }
      catch (err) {
        console.error("Exception occurred at signout() and Exception:", err.message)
      }
    },
    /**
     * goToUserProfileEdit
     */
    goToUserProfileEdit () {
      //
      if (this.$router.currentRoute.path.includes(`user_edit/${this.userData.user_id}`)) {
        return
      }
      if (this.userData.user_id) {
        this.$router.push(`/user_edit/${this.userData.user_id}`)
      }
    },
    /**
     * MenuShowHide
     */
    MenuShowHide () {
      var menu = document.getElementById("navbarSupportedContent")
      if (menu.style.display === "none") {
        menu.style.display = "block"
      }
      else {
        menu.style.display = "none"
      }
    }
  }
}
</script>
